import React, { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { TooltipProps } from '@elo-ui/types'

import { useOverflowed } from '@elo-ui/hooks/use-overflowed'

import './elo-tooltip.scss'

interface TitleProps {
  title: string
}

interface ContentProps {
  content?: JSX.Element | React.ReactNode | (() => JSX.Element)
}

interface RectPart {
  top: number
  left: number
  height: number
  width: number
}

export const EloTooltipTitle: React.FC<TitleProps> = ({ title }) =>
  title && <div className='elo-tooltip__title'>{title}</div>

export const EloTooltipContent: React.FC<ContentProps> = ({ content }) => {
  const Component = typeof content === 'function' ? content : null

  return <div className='elo-tooltip__content'>{Component ? <Component /> : (content as React.ReactNode)}</div>
}

export const EloTooltip: React.FC<TooltipProps> = ({
  className,
  wrapperClassName,
  placement = 'top',
  title,
  children,
  body,
  content,
  show = false,
  pointerPosition = 'center',
  disabled = false,
  hideDelay = 200,
  showDelay = 0,
  withOverflow = false,
}) => {
  const [isVisible, setIsVisible] = useState(show)
  const [isInElement, setIsInElement] = useState(false)
  const [isInBody, setIsInBody] = useState(false)
  const [styles, setStyles] = useState({})

  const wrapperRef = useRef(null)
  const timer = useRef(null)
  const contentRef = useRef(null)
  const childrenRef = useRef(null)

  const { isOverflowed } = useOverflowed(withOverflow ? childrenRef : null)

  const gap = 8

  const classes = classNames('elo-tooltip', `elo-tooltip--${placement}`, `elo-tooltip--${pointerPosition}`, className)
  const wrapperClasses = classNames(
    'elo-tooltip__wrapper',
    {
      'elo-tooltip__wrapper--grid': withOverflow,
    },
    wrapperClassName
  )
  const childrenClasses = classNames({
    'elo-tooltip__overflowed': withOverflow,
  })

  const getStyles = (wrapperRect: RectPart, contentRect: RectPart) => {
    let styles = null

    switch (placement) {
      case 'top':
        styles = {
          top: `${wrapperRect.top - gap - contentRect.height + window.scrollY}px`,
          left: `${wrapperRect.left + wrapperRect.width / 2 + window.scrollX}px`,
        }
        break
      case 'right':
        styles = {
          top: `${wrapperRect.top + wrapperRect.height / 2 + window.scrollY}px`,
          left: `${wrapperRect.left + wrapperRect.width + gap + window.scrollX}px`,
        }
        break
      case 'bottom':
        styles = {
          top: `${wrapperRect.top + wrapperRect.height + gap + window.scrollY}px`,
          left: `${wrapperRect.left + wrapperRect.width / 2 + window.scrollX}px`,
        }
        break
      case 'left':
        styles = {
          top: `${wrapperRect.top + wrapperRect.height / 2 + window.scrollY}px`,
          left: `${wrapperRect.left - gap - contentRect.width + window.scrollX}px`,
        }
        break
    }

    return styles
  }

  const showTooltip = () => {
    timer.current = setTimeout(() => setIsVisible(true), showDelay)
  }

  const hideTooltip = () => {
    timer.current = setTimeout(() => setIsVisible(false), hideDelay)
  }

  useEffect(() => {
    setIsVisible(show)
  }, [show, setIsVisible])

  useEffect(() => {
    const isActive = isInBody || isInElement
    if (timer) {
      clearInterval(timer.current)
    }
    if (isActive) {
      showTooltip()
    } else {
      hideTooltip()
    }
  }, [isInBody, isInElement])

  useEffect(() => {
    const updatePosition = () => {
      if (wrapperRef.current && contentRef.current && isVisible) {
        setStyles(getStyles(wrapperRef.current.getBoundingClientRect(), contentRef.current.getBoundingClientRect()))
      }
    }

    window.addEventListener('resize', updatePosition)

    updatePosition()

    return () => {
      window.removeEventListener('resize', updatePosition)
    }
  }, [placement, isVisible, pointerPosition, show])

  return (
    <div
      className={wrapperClasses}
      ref={wrapperRef}
      onMouseEnter={() => setIsInElement(true)}
      onMouseLeave={() => setIsInElement(false)}
    >
      <span className={childrenClasses} ref={childrenRef}>
        {children}
      </span>
      {isVisible &&
        (!disabled || (withOverflow && isOverflowed)) &&
        createPortal(
          <div
            className={classes}
            ref={contentRef}
            style={styles}
            onMouseEnter={() => setIsInBody(true)}
            onMouseLeave={() => setIsInBody(false)}
          >
            <EloTooltipTitle title={title} />
            <EloTooltipContent content={content} />
            {body}
            <span className='elo-tooltip__arrow' />
          </div>,
          document.body
        )}
    </div>
  )
}
