import {
  PRODUCT_TYPE_IDS,
  PRODUCT_TAXONOMOY_CATEGORIES,
  TRACKING_FORMS,
  WEBHOOK_ENDPOINT_FORMS,
} from './productsShared.constants'

export * from './productsShared.constants'

export const PRODUCTS_LIST_TABLE_KEY = 'PRODUCTS_LIST_TABLE_KEY'

export const MAX_PRODUCT_NAME_LENGTH_IN_ORDER_SELLABLE = 30

export const DEFAULT_UPSELLS_TYPE = ''

export const UPSELLS_SETTINGS = {
  upsell: 'upsell',
  upsellTitle: 'upsellTitle',
  upsellType: 'upsellType',
  mainProductRequired: 'mainProductRequired',
  mainProductSettings: 'mainProductSettings',
  emailForEachItem: 'emailForEachItem',
  bumpsGrouping: 'bumpsGrouping',
}

export const MORE_TOOLTIP_DATA_TYPE = {
  upsells: 'upsells',
  funnels: 'funnels',
  trackingCodes: 'tracking_codes',
  webhookEndpoints: 'webhook_endpoints',
}

export const PRODUCT_TYPE_REQUIRED_APPS = {
  download: false,
  event: false,
  course: false,
  service: false,
  membership: true,
  certificate: true,
  license_key: true,
}

export const PRODUCT_TYPE_APP_NAMES = {
  membership: 'memberships',
  certificate: 'certificates',
  license_key: 'license_keys',
}

export const CAN_NOT_SELL_TEXT = {
  isNotValid: 'not_valid',
  noActivePayMethod: 'no_active_pay_method',
  noPricingPlans: 'no_pricing_plans',
  limitReached: 'limit_reached',
  inReview: 'on_review',
  rejected: 'rejected',
  notForReview: 'not_reviewed',
  notActive: 'not_active',
  noActiveEvents: 'no_active_events',
  noDigitals: 'no_digitals',
  finishRegistration: 'finish_registration',
  noProducts: 'no_products',
  archived: 'is_archived',
  hasAccessPassword: 'has_access_password',
}

export const PRODUCT_FILTERS_LIST = [
  'productType',
  'isPublished',
  'isArchived',
  'isPrivate',
  'isFree',
  'createdAt',
  'multiProductCategory',
  'multiProductIntName',
]

export const getProductTypeLabels = () => ({
  bundle: I18n.t('shared.product_form.bundle'),
  certificate: I18n.t('shared.product_form.certificate'),
  course: I18n.t('shared.product_form.course'),
  courses: I18n.t('shared.product_form.courses'),
  download: I18n.t('shared.product_form.download'),
  downloads: I18n.t('shared.product_form.downloads'),
  event: I18n.t('shared.product_form.event'),
  events: I18n.t('shared.product_form.events'),
  license_key: I18n.t('shared.product_form.license_key'),
  membership: I18n.t('shared.product_form.membership'),
  service: I18n.t('shared.product_form.service'),
})

export const PRODUCT_TYPES_LIST = Object.values(PRODUCT_TYPE_IDS)

export const MAIN_PRODUCT_TYPES_LIST = [PRODUCT_TYPE_IDS.course, PRODUCT_TYPE_IDS.digital, PRODUCT_TYPE_IDS.download]

export const ADDITIONAL_PRODUCT_TYPES_LIST = [
  PRODUCT_TYPE_IDS.membership,
  PRODUCT_TYPE_IDS.eventTickets,
  PRODUCT_TYPE_IDS.certificate,
  PRODUCT_TYPE_IDS.licenseKey,
  PRODUCT_TYPE_IDS.bundle,
]

export const PRODUCT_TAX_CATEGORIES = {
  service: {
    [PRODUCT_TAXONOMOY_CATEGORIES.coaching]: 'digital_services_coaching',
    [PRODUCT_TAXONOMOY_CATEGORIES.membership]: 'digital_services_membership',
    [PRODUCT_TAXONOMOY_CATEGORIES.book]: 'shipping_print_book',
  },
  download: {
    [PRODUCT_TAXONOMOY_CATEGORIES.eBook]: 'digital_download_ebook',
    [PRODUCT_TAXONOMOY_CATEGORIES.musicFiles]: 'digital_download_music_files',
  },
  membership: {
    [PRODUCT_TAXONOMOY_CATEGORIES.membership]: 'digital_services_membership',
    [PRODUCT_TAXONOMOY_CATEGORIES.onlineCourse]: 'digital_online_course',
  },
  course: {
    [PRODUCT_TAXONOMOY_CATEGORIES.onlineCourse]: 'digital_online_course_record',
    [PRODUCT_TAXONOMOY_CATEGORIES.onlineCourseLive]: 'online_course_live',
  },
  certificate: {
    [PRODUCT_TAXONOMOY_CATEGORIES.certificate]: 'digital_download_certificate',
  },
  licenseKey: {
    [PRODUCT_TAXONOMOY_CATEGORIES.licenseCode]: 'digital_download_license_code',
  },
  event: {
    [PRODUCT_TAXONOMOY_CATEGORIES.event]: 'digital_event',
  },
}

export const TRACKING_FORMS_VALUES = Object.values(TRACKING_FORMS)

export const PERFORMANCE_PERIOD = {
  purchaseDate: 'purchase_date',
  relativeDate: 'relative_date',
  customText: 'custom_text',
}
export const PERFORMANCE_PERIOD_VALUES = Object.values(PERFORMANCE_PERIOD)

export const WEBHOOK_ENDPOINT_FORMS_VALUES = Object.values(WEBHOOK_ENDPOINT_FORMS)

export const CERTIFICATE_FORMAT = {
  landscape: 'landscape',
  portrait: 'portrait',
  custom: 'custom',
}

export const PRODUCT_START_FORM = {
  fromStaticDate: 'from_static_date',
  fromPayment: 'from_payment',
}

export const PRODUCT_DURATION_MAX = 300
export const PRODUCT_DURATION_MIN = 1

export const PRODUCT_UPSELL_STATUS = {
  activated: 'activated',
  deactivated: 'deactivated',
}

export const FORM_FUNNEL = 'funnel'

export const TOOLTIP_MENU_ITEM_TYPES = {
  productLinks: 'productLinks',
  edit: 'edit',
  preview: 'preview',
  productPage: 'productPage',
  archive: 'archive',
  duplicate: 'duplicate',
  unpublish: 'unpublish',
  unarchive: 'unarchive',
  publish: 'publish',
}

const PAYMENT_REDIRECTION_PARAMS = [
  'payment_id',
  'state',
  'transaction_id',
  'subscription_id',
  'order_token',
  'period_type',
  'campaign_id',
  'add_id_1',
  'add_id_2',
  'account_id',
]

const PAYER_REDIRECTION_PARAMS = [
  'payer_email',
  'payer_first_name',
  'payer_last_name',
  'payer_country',
  'payer_country_code',
  'payer_city',
  'payer_street',
  'payer_street_number',
  'payer_zip',
  'payer_company',
  'payer_vat_no',
  'payer_phone',
]

const PRODUCT_REDIRECTION_PARAMS = [
  'ticket_id',
  'ticket_date',
  'product_id',
  'product',
  'price',
  'pricing_plan_ids',
  'internal_redirect',
]

const ADDITIONAL_REDIRECTION_PARAMS = [
  'pid',
  'prid',
  'recurring',
  'recurring_form',
  'coupon_code',
  'performance_period',
  'order_id',
  'payment_session_id',
  'payment_session_token',
  'affiliate_program_id',
]

const GIFT_RECEIVER_PARAMS = [
  'gift_receiver_email',
  'gift_receiver_first_name',
  'gift_receiver_last_name',
  'gift_receiver_country',
  'gift_receiver_country_code',
  'gift_receiver_city',
  'gift_receiver_street',
  'gift_receiver_street_number',
  'gift_receiver_zip',
  'gift_receiver_company',
  'gift_receiver_vat_no',
  'gift_receiver_phone',
]

export const REDIRECTION_PARAMS = {
  payment: PAYMENT_REDIRECTION_PARAMS,
  payer: PAYER_REDIRECTION_PARAMS,
  product: PRODUCT_REDIRECTION_PARAMS,
  additional: ADDITIONAL_REDIRECTION_PARAMS,
  receiver: GIFT_RECEIVER_PARAMS,
}

export const REDIRECTION_PARAMS_KEYS = Object.keys(REDIRECTION_PARAMS)

export const PRODUCTS_OVERVIEW_CSV_PARAMS = [
  {
    label: I18n.t('react.cabinet.common.id'),
    csvKey: 'id',
  },
  {
    label: I18n.t('react.cabinet.product.list.product'),
    csvKey: 'name',
  },
  {
    label: I18n.t('react.cabinet.product.created'),
    csvKey: 'created_at',
  },
  {
    label: I18n.t('react.cabinet.product.edited'),
    csvKey: 'updated_at',
  },
  {
    label: I18n.t('react.cabinet.product.sell'),
    csvKey: 'sell',
  },
  {
    label: I18n.t('react.cabinet.product.published'),
    csvKey: 'published',
  },
  {
    label: I18n.t('react.cabinet.common.price'),
    csvKey: 'display_price',
  },
  {
    label: I18n.t('react.cabinet.common.limit'),
    csvKey: 'limit',
  },
  {
    label: I18n.t('react.cabinet.common.sold'),
    csvKey: 'sold_count',
  },
  {
    label: I18n.t('react.cabinet.common.reserved'),
    csvKey: 'reserved_count',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product_category'),
    csvKey: 'product_groups',
  },
  {
    label: I18n.t('react.cabinet.product.form.internal_name'),
    csvKey: 'internal_name',
  },
  {
    label: I18n.t('react.cabinet.common.cost_center'),
    csvKey: 'cost_center',
  },
]

export const COURSES_OVERVIEW_CSV_PARAMS = [
  {
    label: I18n.t('react.cabinet.common.id'),
    csvKey: 'id',
  },
  {
    label: I18n.t('react.cabinet.product.list.product'),
    csvKey: 'name',
  },
  {
    label: I18n.t('react.cabinet.product.created'),
    csvKey: 'created_at',
  },
  {
    label: I18n.t('react.cabinet.product.edited'),
    csvKey: 'updated_at',
  },
  {
    label: I18n.t('react.cabinet.product.sell'),
    csvKey: 'sell',
  },
  {
    label: I18n.t('react.cabinet.product.published'),
    csvKey: 'published',
  },
  {
    label: I18n.t('react.cabinet.common.access'),
    csvKey: 'access_count',
  },
  {
    label: I18n.t('react.cabinet.common.price'),
    csvKey: 'price',
  },
  {
    label: I18n.t('react.cabinet.common.limit'),
    csvKey: 'limit',
  },
  {
    label: I18n.t('react.cabinet.common.sold'),
    csvKey: 'sold_count',
  },
  {
    label: I18n.t('react.cabinet.common.reserved'),
    csvKey: 'reserved_count',
  },
]

export const ADMIN_PRODUCTS_CSV_PARAMS = [
  {
    label: 'ID',
    sortingKey: 'id',
  },
  {
    label: 'Product',
    sortingKey: 'name',
  },
  {
    label: 'Form',
    sortingKey: 'form',
  },
  {
    label: 'Seller',
    sortingKey: 'seller_id',
  },
  {
    label: 'Sold',
    sortingKey: 'sold_count',
  },
  {
    label: 'Reserved',
    sortingKey: 'reserved_count',
  },
  {
    label: 'Created',
    sortingKey: 'created_at',
  },
  {
    label: 'Updated',
    sortingKey: 'updated_at',
  },
]

export const PRODUCT_TYPE_CATEGORY_MATRIX = {
  [PRODUCT_TYPE_IDS.digital]: {
    categories: PRODUCT_TAX_CATEGORIES.service,
    default: PRODUCT_TAXONOMOY_CATEGORIES.membership,
  },
  [PRODUCT_TYPE_IDS.download]: {
    categories: PRODUCT_TAX_CATEGORIES.download,
    default: PRODUCT_TAXONOMOY_CATEGORIES.eBook,
  },
  [PRODUCT_TYPE_IDS.membership]: {
    categories: PRODUCT_TAX_CATEGORIES.membership,
    default: PRODUCT_TAXONOMOY_CATEGORIES.onlineCourse,
  },
  [PRODUCT_TYPE_IDS.course]: {
    categories: PRODUCT_TAX_CATEGORIES.course,
    default: PRODUCT_TAXONOMOY_CATEGORIES.onlineCourse,
  },
  [PRODUCT_TYPE_IDS.eventTickets]: {
    categories: PRODUCT_TAX_CATEGORIES.event,
    default: PRODUCT_TAXONOMOY_CATEGORIES.event,
  },
  [PRODUCT_TYPE_IDS.licenseKey]: {
    categories: PRODUCT_TAX_CATEGORIES.licenseKey,
    default: PRODUCT_TAXONOMOY_CATEGORIES.licenseCode,
  },
  [PRODUCT_TYPE_IDS.certificate]: {
    categories: PRODUCT_TAX_CATEGORIES.certificate,
    default: PRODUCT_TAXONOMOY_CATEGORIES.certificate,
  },
}

const COMMON_TABS = [
  I18n.t('react.cabinet.product.step_buttons.checkout'),
  I18n.t('react.cabinet.product.step_buttons.market_upsell'),
  I18n.t('react.cabinet.product.step_buttons.delivery'),
  I18n.t('react.cabinet.product.step_buttons.pages'),
  I18n.t('react.cabinet.product.step_buttons.advanced'),
]

export const PRODUCT_TABS = {
  [PRODUCT_TYPE_IDS.course]: [
    I18n.t('react.cabinet.product.step_buttons.product_details'),
    I18n.t('react.cabinet.product.step_buttons.content'),
    ...COMMON_TABS,
  ],
  [PRODUCT_TYPE_IDS.digital]: [I18n.t('react.cabinet.product.step_buttons.product_details'), ...COMMON_TABS],
  [PRODUCT_TYPE_IDS.download]: [
    I18n.t('react.cabinet.product.step_buttons.product_details'),
    I18n.t('react.cabinet.product.step_buttons.file_upload'),
    ...COMMON_TABS,
  ],
  [PRODUCT_TYPE_IDS.membership]: [
    I18n.t('react.cabinet.product.step_buttons.product_details'),
    I18n.t('react.cabinet.product.step_buttons.products'),
    ...COMMON_TABS,
  ],
  [PRODUCT_TYPE_IDS.eventTickets]: [
    I18n.t('react.cabinet.product.step_buttons.product_details'),
    I18n.t('react.cabinet.product.step_buttons.tickets_details'),
    I18n.t('react.cabinet.product.step_buttons.design'),
    ...COMMON_TABS,
  ],
  [PRODUCT_TYPE_IDS.certificate]: [
    I18n.t('react.cabinet.product.step_buttons.product_details'),
    I18n.t('react.cabinet.product.step_buttons.keys_details'),
    I18n.t('react.cabinet.product.step_buttons.design'),
    ...COMMON_TABS,
  ],
  [PRODUCT_TYPE_IDS.licenseKey]: [
    I18n.t('react.cabinet.product.step_buttons.product_details'),
    I18n.t('react.cabinet.product.step_buttons.keys'),
    ...COMMON_TABS,
  ],
  [PRODUCT_TYPE_IDS.bundle]: [
    I18n.t('react.cabinet.product.step_buttons.product_details'),
    I18n.t('react.cabinet.product.products'),
    ...COMMON_TABS,
  ],
}

export const PRODUCT_TITLE = {
  [PRODUCT_TYPE_IDS.course]: I18n.t('react.shared.product_form.online_course'),
  [PRODUCT_TYPE_IDS.certificate]: I18n.t('react.shared.product_form.certificate'),
  [PRODUCT_TYPE_IDS.eventTickets]: I18n.t('react.shared.product_form.eticket'),
  [PRODUCT_TYPE_IDS.licenseKey]: I18n.t('react.shared.product_form.code_or_license'),
  [PRODUCT_TYPE_IDS.membership]: I18n.t('react.shared.product_form.member_area'),
  [PRODUCT_TYPE_IDS.digital]: I18n.t('react.shared.product_form.checkout_page'),
  [PRODUCT_TYPE_IDS.download]: I18n.t('react.shared.product_form.download_file'),
  [PRODUCT_TYPE_IDS.bundle]: I18n.t('react.shared.product_form.bundle'),
}

export const PRODUCT_DUPLICATION_IN_PROGRESS = I18n.t('react.cabinet.product.duplication_in_progress')
