import { getElopageConfig } from 'utils/elopageConfig.utils'

import { CURRENT_YEAR } from '@elo-kit/constants/dateTime.constants'

export const DEFAULT_FAVICON = '/favicon.ico'

export const ELOPAGE_PROFILE_CABINETS = {
  seller: 'cabinet',
  publisher: 'publisher',
  elo_publisher: 'ablefy_affiliate',
  team_member: 'team_member',
  payer: 'payer',
  manager: 'admin',
  shop: 'shop',
  ablefy_affiliate: 'ablefy_affiliate',
}

export const ELOPAGE_CABINETS_URL = {
  ...ELOPAGE_PROFILE_CABINETS,
  publisher: 'publish',
}

export const THEME_TYPE = {
  shop: 'ShopTheme',
  membership: 'MembershipTheme',
  course: 'course',
  funnel: 'funnel',
  affiliate: 'affiliate',
  thankYouPage: 'thankYouPage',
}

export const CATEGORY_AREAS = {
  ShopTheme: 'shop',
  MembershipTheme: 'membership',
  course: 'course',
  product: 'product',
}

export const POSITIONS = {
  bottom: 'bottom',
  center: 'center',
  left: 'left',
  right: 'right',
  top: 'top',
  dynamic: 'dynamic',
}

export const ELO_GTM_CODES = {
  development: 'GTM-TQHBT68',
  staging: 'GTM-M3KN6P8',
  production: 'GTM-M3KN6P8',
}

export const getAllowedLanguages = () => ({
  en: {
    label: I18n.t('react.shared.english'),
    value: 'en',
  },
  de: {
    label: I18n.t('react.shared.german'),
    value: 'de',
  },
})

export const getLanguages = () => ({
  ...getAllowedLanguages(),
  es: {
    label: I18n.t('react.shared.spanish'),
    value: 'es',
  },
  ar: {
    label: I18n.t('react.shared.arabic'),
    value: 'ar',
  },
  cs: {
    label: I18n.t('react.shared.czech'),
    value: 'cs',
  },
  da: {
    label: I18n.t('react.shared.danish'),
    value: 'da',
  },
  el: {
    label: I18n.t('react.shared.greek'),
    value: 'el',
  },

  fr: {
    label: I18n.t('react.shared.french'),
    value: 'fr',
  },
  hu: {
    label: I18n.t('react.shared.hungarian'),
    value: 'hu',
  },
  it: {
    label: I18n.t('react.shared.italian'),
    value: 'it',
  },
  pl: {
    label: I18n.t('react.shared.polish'),
    value: 'pl',
  },
  ptBr: {
    label: I18n.t('react.shared.portuguese_br'),
    value: 'pt_br',
  },
  ptPt: {
    label: I18n.t('react.shared.portuguese_pt'),
    value: 'pt_pt',
  },
  sv: {
    label: I18n.t('react.shared.swedish'),
    value: 'sv',
  },
  tr: {
    label: I18n.t('react.shared.turkish'),
    value: 'tr',
  },
  ko: {
    label: I18n.t('react.shared.korean'),
    value: 'ko',
  },
  no: {
    label: I18n.t('react.shared.norwegian'),
    value: 'no',
  },
  hr: {
    label: I18n.t('react.shared.croatian'),
    value: 'hr',
  },
  ro: {
    label: I18n.t('react.shared.romanian'),
    value: 'ro',
  },
  nl: {
    label: I18n.t('react.shared.nederland'),
    value: 'nl',
  },
})

interface Language {
  label: string
  value: string
}

export const ALLOWED_LANGUAGES: Record<string, Language> = getAllowedLanguages()
export const ALLOWED_ADMIN_LANGUAGES: Record<string, Language> = {
  en: {
    label: I18n.t('react.shared.english'),
    value: 'en',
  },
  de: {
    label: I18n.t('react.shared.german'),
    value: 'de',
  },
  es: {
    label: I18n.t('react.shared.spanish'),
    value: 'es',
  },
}
export const LANGUAGES: Record<string, Language> = getLanguages()

export const conversionToolsVideos = {
  [LANGUAGES.de.value]: 'https://img.ablefy.io/videos/ConversionTools_DE.mov',
  [LANGUAGES.en.value]: 'https://img.ablefy.io/videos/ConversionTools_ENG.mov',
}

export const dunningPowerSellerVideos = {
  [LANGUAGES.de.value]: 'https://img.ablefy.io/videos/PowerSellers_DE_V2.mp4',
  [LANGUAGES.en.value]: 'https://img.ablefy.io/videos/PowerSellers_ENG_V2.mp4',
}
export const dunningNormalSellerVideos = {
  [LANGUAGES.de.value]: 'https://img.ablefy.io/videos/NormalSellers_DE_V2.mp4',
  [LANGUAGES.en.value]: 'https://img.ablefy.io/videos/NormalSellers_ENG_V2.mp4',
}

export const STATUSES = {
  completed: 'completed',
  approved: 'approved',
  accepted: 'accepted',
  active: 'active',
  canceled: 'canceled',
  paused: 'paused',
  requested: 'requested',
  rejected: 'rejected',
  success: 'successful',
  pending: 'pending',
  expired: 'expired',
  error: 'error',
  waiting: 'waiting',
  denied: 'denied',
  declined: 'declined',
  noInvoices: 'no_invoices',
  activated: 'activated',
  valid: 'valid',
  partiallyPaid: 'partially_paid',
  failed: 'failed',
  successful: 'success',
}

const webProtocol = getElopageConfig('webProtocol')
const webHost = getElopageConfig('webHost')

export const ROOT_URL = `${webProtocol}://${webHost}`
export const DEBOUNCE_ACTIONS = {
  clear: 'clear',
  add: 'add',
}

export const COPYRIGHT = `© ablefy ${CURRENT_YEAR}`

export const BROWSERS = ['Chrome', 'Edge', 'Firefox', 'Safari']
export const getLocalePostfix = () => I18n.locale.replace(/\b\w/g, (l) => l.toUpperCase())
export const SCREEN_SIZE = {
  mobile: 576,
  tablet: 768,
  desktop: 992,
}

export const ELOPAGE_COOKIEBOT_CONFIG = {
  cookiebotId: '378c6f68-0c04-4a5f-85f5-8b139fef40d9',
  cookiebotMode: 'none',
}

export const ENVIRONMENT = {
  production: 'production',
  development: 'development',
  staging: 'staging',
  uat: 'uat',
}

export const MOBILE_OPERATING_SYSTEMS = {
  android: 'Android',
  iOS: 'iOS',
  windowsPhone: 'Windows Phone',
  unknown: 'unknown',
}

export const IS_REQUIRED = {
  required: 'required',
  notRequired: 'not_required',
}

export const FORBIDDEN_STATUS_CODE = '403'
export const SUCCESS_STATUS_CODE = 200

export const noop = () => {}

export const APP_NAMES = {
  app: 'app',
  shop: 'shop',
  cabinets: 'cabinets',
}

export const FREE_PRICE = '0,00'

export const USERFLOW_INIT_TOKEN = {
  development: 'ct_tnpybw7ndrbhdp37iug6wqujvq',
  staging: 'ct_tnpybw7ndrbhdp37iug6wqujvq',
  uat: 'ct_tnpybw7ndrbhdp37iug6wqujvq',
  production: 'ct_b4wjy4ndf5c5rag7y7kehtkpki',
}

export const USERFLOW_LOCALE_CODE = {
  en: 'en-US',
  de: 'de-DE',
}
