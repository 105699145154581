import React from 'react'

import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloMarketplaceIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M7.85714 7C7.85714 6.72386 7.6173 6.5 7.32143 6.5C7.02556 6.5 6.78571 6.72386 6.78571 7C6.78571 8.65685 8.2248 10 10 10C11.7752 10 13.2143 8.65685 13.2143 7C13.2143 6.72386 12.9744 6.5 12.6786 6.5C12.3827 6.5 12.1429 6.72386 12.1429 7C12.1429 8.10457 11.1835 9 10 9C8.81653 9 7.85714 8.10457 7.85714 7Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.5 5C2.5 3.89543 3.45939 3 4.64286 3H15.3571C16.5406 3 17.5 3.89543 17.5 5V15C17.5 16.1046 16.5406 17 15.3571 17H4.64286C3.45939 17 2.5 16.1046 2.5 15V5ZM4.64286 4H15.3571C15.9489 4 16.4286 4.44772 16.4286 5V15C16.4286 15.5523 15.9489 16 15.3571 16H4.64286C4.05112 16 3.57143 15.5523 3.57143 15V5C3.57143 4.44772 4.05112 4 4.64286 4Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
