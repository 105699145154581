import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloDeleteCircleIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      d='M13.3536 6.64645C13.5488 6.84171 13.5488 7.15829 13.3536 7.35355L10.7071 10L13.3536 12.6464C13.5488 12.8417 13.5488 13.1583 13.3536 13.3536C13.1583 13.5488 12.8417 13.5488 12.6464 13.3536L10 10.7071L7.35355 13.3536C7.15829 13.5488 6.84171 13.5488 6.64645 13.3536C6.45118 13.1583 6.45118 12.8417 6.64645 12.6464L9.29289 10L6.64645 7.35355C6.45118 7.15829 6.45118 6.84171 6.64645 6.64645C6.84171 6.45118 7.15829 6.45118 7.35355 6.64645L10 9.29289L12.6464 6.64645C12.8417 6.45118 13.1583 6.45118 13.3536 6.64645Z'
      fill='#000000'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10ZM10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5Z'
      fill='#000000'
    />
  </EloBaseIcon>
)
