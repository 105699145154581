import moment from 'moment'
import 'moment-timezone'
import { DATE_FORMATS, TIME_UNITS_IN_SEC } from '@elo-kit/constants/dateTime.constants'

export const isIsoDate = (str) => {
  if (!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(str)) return false
  const d = new Date(str)
  return (d || {}).toISOString() === str
}

export const increaseDay = (d) => moment(d, DATE_FORMATS.INPUT).add(1, 'd').toISOString()

export const formatForInput = (time) => moment(time, DATE_FORMATS.DDMMYYYY).format(DATE_FORMATS.INPUT)

export const isValidDateValue = (time) => moment(time, DATE_FORMATS.DDMMYYYY).isValid()

const createMomentUtcDateGetter =
  (name) =>
  ({ date, format, type }) =>
    moment.utc(date, format)[name](type)

export const getMomentUtcStartOf = createMomentUtcDateGetter('startOf')
export const getMomentUtcEndOf = createMomentUtcDateGetter('endOf')

const setDateFormat = (format) => (date, currentFormat) => moment(date, currentFormat).format(format)

export const [createFormattedDateTime, createFormattedDate, formatToDefault] = [
  DATE_FORMATS.DDMMYYYYHHmm,
  DATE_FORMATS.DDMMYYYY,
  DATE_FORMATS.default,
].map(setDateFormat)

export const getTimeLeftObject = (date, isBeforeCurrentDate = false, params) => {
  const { withoutWeeks = false, withoutDays = false } = params || {}

  const neededDate = isBeforeCurrentDate
    ? Date.parse(new Date()) - Date.parse(new Date(date))
    : Date.parse(new Date(date)) - Date.parse(new Date())

  let diff = neededDate / 1000

  // when date is reached return false (for clearing countdown)
  if (diff <= 0) {
    return false
  }

  const timeLeft = {
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }

  if (diff >= TIME_UNITS_IN_SEC.week && !withoutWeeks) {
    // 24 * 60 * 60 * 7
    timeLeft.weeks = Math.floor(diff / TIME_UNITS_IN_SEC.week)
    diff -= timeLeft.weeks * TIME_UNITS_IN_SEC.week
  }
  if (diff >= TIME_UNITS_IN_SEC.day && !withoutDays) {
    // 24 * 60 * 60
    timeLeft.days = Math.floor(diff / TIME_UNITS_IN_SEC.day)
    diff -= timeLeft.days * TIME_UNITS_IN_SEC.day
  }
  if (diff >= TIME_UNITS_IN_SEC.hour) {
    // 60 * 60
    timeLeft.hours = Math.floor(diff / TIME_UNITS_IN_SEC.hour)
    diff -= timeLeft.hours * TIME_UNITS_IN_SEC.hour
  }
  if (diff >= TIME_UNITS_IN_SEC.minute) {
    timeLeft.minutes = Math.floor(diff / TIME_UNITS_IN_SEC.minute)
    diff -= timeLeft.minutes * TIME_UNITS_IN_SEC.minute
  }
  timeLeft.seconds = diff

  return timeLeft
}

export const getTwoCharactersTime = (time) => (time.toString().length < 2 ? `0${time}` : time)

export const convertMillisecondsToTimeString = (milliseconds) => {
  let seconds = Math.floor(milliseconds / 1000)
  let minute = Math.floor(seconds / 60)
  const hour = getTwoCharactersTime(Math.floor(minute / 60))
  seconds = getTwoCharactersTime(seconds % 60)
  minute = getTwoCharactersTime(minute % 60)

  return `${hour}:${minute}:${seconds}`
}

// parse '1m' this to number and letter
export const parseShrinkDate = (period = '') => {
  const [number, letter] = (period || '').split(/(\d+)/).filter(Boolean)

  return {
    number,
    letter,
  }
}

// concat 1 and 'm' to '1 month' format
export const concatParsedShrinkDate = (str) => {
  const { number, letter } = parseShrinkDate(str)
  const single = Number(number) === 1
  let localeKey = ''

  if (letter === 'd') localeKey = single ? I18n.t('react.shared.common.day') : I18n.t('react.shared.common.days')
  if (letter === 'm') localeKey = single ? I18n.t('react.shared.common.month') : I18n.t('react.shared.common.months')
  if (letter === 'y') localeKey = single ? I18n.t('react.shared.common.year') : I18n.t('react.shared.common.years')

  return `${number} ${localeKey}`
}

export const formatTimeForCountdown = (value) => (Number(value) < 10 ? '0'.concat(value) : value)

export const getDifferenceOfTime = (startDate, endDate, getHours = false) => {
  const difference = new Date(endDate).getTime() - new Date(startDate).getTime()

  if (getHours) {
    return Math.ceil(difference / (1000 * 3600))
  }

  return Math.ceil(difference / (1000 * 3600 * 24))
}

// date (2023-06-29) is a business rule
export const isUserCreatedAfter06292023 = (createdAt) => moment(createdAt).isAfter(moment('2023-06-29'))

export const isValidBirthdayDate = (currentDate) => {
  const MIN_AGE = 18
  const minAllowedDate = moment().subtract(MIN_AGE, 'years')

  return moment(currentDate).isSameOrBefore(minAllowedDate, 'day')
}
