import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloDeviceDesktopIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.875 13.75C16.875 14.0952 16.5952 14.375 16.25 14.375H10H3.75C3.40482 14.375 3.125 14.0952 3.125 13.75V12.5H16.875V13.75ZM10.625 15.625H16.25C17.2855 15.625 18.125 14.7855 18.125 13.75V11.875V5C18.125 3.96447 17.2855 3.125 16.25 3.125H3.75C2.71447 3.125 1.875 3.96447 1.875 5V11.875V13.75C1.875 14.7855 2.71447 15.625 3.75 15.625H9.375V16.875H7.5C7.15482 16.875 6.875 17.1548 6.875 17.5C6.875 17.8452 7.15482 18.125 7.5 18.125H10H12.5C12.8452 18.125 13.125 17.8452 13.125 17.5C13.125 17.1548 12.8452 16.875 12.5 16.875H10.625V15.625ZM3.125 5V11.25H16.875V5C16.875 4.65482 16.5952 4.375 16.25 4.375H3.75C3.40482 4.375 3.125 4.65482 3.125 5Z'
      fill='currentColor'
    />
  </EloBaseIcon>
)
