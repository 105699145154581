import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloChildIndicatorIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <g clipPath='url(#clip0_838_2848)'>
      <path
        d='M6.75 5.25V14.7499H13.25'
        stroke='currentColor'
        stroke-width='1.25'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_838_2848'>
        <rect width='20' height='20' fill='currentColor' />
      </clipPath>
    </defs>
  </EloBaseIcon>
)
