import React from 'react'
import { EloBaseIcon, EloBaseIconProps } from '../_base'

export const EloArrowUpIcon: React.FC<EloBaseIconProps> = (props) => (
  <EloBaseIcon {...props}>
    <g clipPath='url(#clip0_2801_25403)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 5.57422C8.58579 5.57422 8.25 5.91001 8.25 6.32422C8.25 6.73843 8.58579 7.07422 9 7.07422H16.7893L5.46967 18.3939C5.17678 18.6868 5.17678 19.1617 5.46967 19.4545C5.76256 19.7474 6.23744 19.7474 6.53033 19.4545L18 7.98488V16.0742C18 16.4884 18.3358 16.8242 18.75 16.8242C19.1642 16.8242 19.5 16.4884 19.5 16.0742V6.32422C19.5 5.91001 19.1642 5.57422 18.75 5.57422H9Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_2801_25403'>
        <rect width='24' height='24' fill='white' transform='translate(0 0.925781)' />
      </clipPath>
    </defs>
  </EloBaseIcon>
)
