import React, { useState, useEffect } from 'react'

export const useOverflowed = (ref: React.RefObject<HTMLElement> | null) => {
  const [isOverflowed, setIsOverflow] = useState(false)

  useEffect(() => {
    const checkTruncation = () => {
      if (ref?.current) {
        setIsOverflow(ref.current.scrollWidth > ref.current.clientWidth)
      }
    }

    checkTruncation()
    window.addEventListener('resize', checkTruncation)

    return () => {
      window.removeEventListener('resize', checkTruncation)
    }
  }, [])

  return { isOverflowed }
}
