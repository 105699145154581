export const FILE_EXTENSION_REGEX = /\.([0-9a-z]+)(?:[\?#]|$)/i
export const EXTENSION_REGEX = /\.[^/.]+$/

export const EMAIL_REGEX = /.+@.+\..+/i
export const PHONE_NUMBER_REGEX = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const BIC_REGEX = /^([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$/

export const HHMM_REGEX = /^\d{2}:\d{2}$/
export const DDMMYYYY_REGEX = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\.(0[1-9]|1[0-2])\.(1[8-9]\d{2}|20[0-1][0-9])$/
export const DDMM_REGEX = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\.(0[1-9]|1[0-2])$/
export const MMYYYY_REGEX = /^\d{2}\.\d{4}$/
export const YYYY_REGEX = /^\d{4}$/

export const COMMA_REGEX = /[ ,]+/

export const EXCLUDE_DOT_REGEX = /^[^.]*$/
export const EXCLUDE_WHITE_SPACE_REGEX = /(?!^[ ]*$)^\S+$/

export const HTML_REGEX = /(<([^>]+)>)/i

export const DATE_FIELD_VALUE_REGEX = /[^\d.]/g

export const UKRAINIAN_LETTERS_REGEX = /[А-ЩЬЮЯҐЄІЇа-щьюяґєії]/

export const IOS_BUNDLE_ID_REGEX = /^[a-z0-9]+((\.|-)[a-z0-9]+)+$/gi
export const ANDROID_BUNDLE_ID_REGEX = /^[a-z0-9]+((\.)[a-z0-9]+)+$/gi

export const URL_REGEX =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[\u00C0-\u017Fa-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/

export const USERNAME_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,100}[a-zA-Z0-9]+$/
export const WHITE_SPACES_REGEX = /\s/g
export const CHAT_WHITE_SPACES_REGEX = /^\s+/g
export const CHAT_GAPS_REGEX = /(?:\r\n|\r|\n)/g
export const NUMBER_REGEX = /\d/g

export const URL_LINKIFY_REGEX = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
export const URL_WITHOUT_PROTOCOL_REGEX = /(^|[^\/])(www\.[\S]+(\b|$))/gim
export const MAIL_REGEX = /([\w+[\-\.]+@([\w-]+\.)+[\w-]{2,15}$)/gim
export const URL_SLUG_REGEX = /^[a-zA-Z0-9-]+$/
export const SCRIPT_TAGS_WITH_SRC_ATTRIBUTE_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
export const VALUE_BEFORE_FIRST_DOT_REGEX = /^[^\.]*/

export const EU_VAT_REGEX = {
  AT: /^(AT)?U[0-9]{8}$/,
  BE: /^(BE)?[0-1][0-9]{9}/,
  BG: /^(BG)?[0-9]{9,10}$/,
  CY: /^(CY)?[0-9]{8}[A-Z]{1}$/,
  CZ: /^(CZ)?[0-9]{8,10}$/,
  DE: /^(DE)?[0-9]{9}$/,
  DK: /^(DK)?[0-9]{8}$/,
  EE: /^(EE)?[0-9]{9}$/,
  GR: /^(EL|GR)?[0-9]{9}$/,
  ES: /^(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]$/,
  FI: /^(FI)?[0-9]{8}$/,
  FR: /^(FR)?[0-9A-Z]{2}[0-9]{9}$/,
  GB: /^(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$/,
  HR: /^(HR)?[0-9]{11}$/,
  HU: /^(HU)?[0-9]{8}$/,
  IE: /^(IE)([0-9A-Z\*\+]{7}[A-Z]{1,2}$)/,
  IT: /^(IT)?[0-9]{11}$/,
  LT: /^(LT)?([0-9]{9}|[0-9]{12})$/,
  LU: /^(LU)?[0-9]{8}$/,
  LV: /^(LV)?[0-9]{11}$/,
  MT: /^(MT)?[0-9]{8}$/,
  NL: /^(NL)?[0-9]{9}B[0-9]{2}$/,
  PL: /^(PL)?[0-9]{10}$/,
  PT: /^(PT)?[0-9]{9}$/,
  RO: /^(RO)?[0-9]{2,10}$/,
  SE: /^(SE)?[0-9]{12}$/,
  SI: /^(SI)?[0-9]{8}$/,
  SK: /^(SK)?[0-9]{10}$/,
  CH: /^(CHE-)?[0-9]{3}.[0-9]{3}.[0-9]{3}(IVA|TVA|MWST){0,4}$/,
}

export const PRODUCT_KEY_REGEX = /%{([a-zA-Z]+)_(\d+)}/

export const FONT_FAMILY_NAME_REGEX = /^[a-zA-Z][a-zA-Z0-9_-\s]+$/
