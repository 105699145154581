export const PAYMENT_STATES = {
  paymentWaiting: 'payment_waiting',
  paymentPending: 'payment_pending',
  paying: 'paying',
  paid: 'paid',
  paymentPaused: 'payment_paused',
  paymentCanceled: 'payment_canceled',
  paymentRefunded: 'payment_refunded',
  paymentNotCompleted: 'payment_not_completed',
  paymentChargebacked: 'payment_chargebacked',
}

export const PAYMENT_STATES_LIST = Object.values(PAYMENT_STATES)

export const VAT_TYPE = {
  gross: 'gross',
  net: 'net',
}

export const SUCCESSFUL_ORDER_STATES = [
  PAYMENT_STATES.paying,
  PAYMENT_STATES.paid,
  PAYMENT_STATES.paymentWaiting,
  PAYMENT_STATES.paymentPending,
]

export const ORDER_STATES_NON_PAYABLE = [
  PAYMENT_STATES.paying,
  PAYMENT_STATES.paid,
  PAYMENT_STATES.paymentPaused,
  PAYMENT_STATES.paymentCanceled,
  PAYMENT_STATES.paymentRefunded,
  PAYMENT_STATES.paymentChargebacked,
]

export const SELLABLE_TYPES = {
  product: 'Product',
  ticket: 'Ticket',
  upsell: 'Upsell',
}

export const PRICING_PLAN_FORMS_PRIO = {
  one_time: 0,
  installment: 1,
  limited_subscription: 2,
  subscription: 3,
}

export const ORDER_VIEW_TYPES = {
  owner: 'owner',
  payer: 'payer',
}

export const ORDER_TYPES = {
  standard: 'standard',
  bundle: 'bundle',
  bump: 'bump',
  upgrade: 'ticket_upgrade',
}
