import moment from 'moment-timezone'
import { Nullable } from 'types/helpers'
import { DATE_FORMATS, TIME_UNITS_IN_SEC } from '../constants/dateTime.constants'

interface TimeLeft {
  weeks: number
  days: number
  hours: number
  minutes: number
  seconds: number
}

export const getTimeLeftObject = (date: Date): boolean | TimeLeft => {
  let diff = (Date.parse(String(new Date(date))) - Date.parse(String(new Date()))) / 1000

  /*
    When date is reached return
    false (for clearing countdown)
  */
  if (diff <= 0) {
    return false
  }

  const timeLeft: TimeLeft = {
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }

  if (diff >= TIME_UNITS_IN_SEC.week) {
    timeLeft.weeks = Math.floor(diff / TIME_UNITS_IN_SEC.week)
    diff -= timeLeft.weeks * TIME_UNITS_IN_SEC.week
  }
  if (diff >= TIME_UNITS_IN_SEC.day) {
    timeLeft.days = Math.floor(diff / TIME_UNITS_IN_SEC.day)
    diff -= timeLeft.days * TIME_UNITS_IN_SEC.day
  }
  if (diff >= TIME_UNITS_IN_SEC.hour) {
    timeLeft.hours = Math.floor(diff / TIME_UNITS_IN_SEC.hour)
    diff -= timeLeft.hours * TIME_UNITS_IN_SEC.hour
  }
  if (diff >= TIME_UNITS_IN_SEC.minute) {
    timeLeft.minutes = Math.floor(diff / TIME_UNITS_IN_SEC.minute)
    diff -= timeLeft.minutes * TIME_UNITS_IN_SEC.minute
  }

  timeLeft.seconds = diff

  return timeLeft
}

export const formatDateTime = (time: string, format?: Nullable<string>, timezone?: Nullable<string>): string => {
  if (!time) {
    return ''
  }

  const form = format || DATE_FORMATS.DDMMYYYYHHmm
  let momentD = moment(time, moment.ISO_8601)

  if (timezone) {
    momentD = momentD.tz(timezone)
  }

  return momentD.format(form)
}

export const parseShrinkDate = (period = ''): { letter: string; number: string } => {
  const [number, letter] = period.split(/(\d+)/).filter(Boolean)

  return {
    number,
    letter,
  }
}

export const concatParsedShrinkDate = (str: string, germanPlural = false): string => {
  const { number, letter } = parseShrinkDate(str)
  const isSingle = Number(number) === 1

  const translations = {
    d: {
      singular: I18n.t('react.shared.common.day'),
      plural: I18n.t('react.shared.common.days'),
      germanPlural: I18n.t('react.shared.common.in_days'),
    },
    m: {
      singular: I18n.t('react.shared.common.month'),
      plural: I18n.t('react.shared.common.months'),
      germanPlural: I18n.t('react.shared.common.in_months'),
    },
    y: {
      singular: I18n.t('react.shared.common.year'),
      plural: I18n.t('react.shared.common.years'),
      germanPlural: I18n.t('react.shared.common.in_years'),
    },
  }

  const translationKey = translations[letter]

  if (!translationKey) return str

  let localeKey = translationKey.plural

  if (isSingle) {
    localeKey = translationKey.singular
  } else if (germanPlural) {
    localeKey = translationKey.germanPlural
  }

  return `${number} ${localeKey}`
}

export const in10000Days = (): Date => {
  const date = new Date()

  date.setTime(date.getTime() + 10000 * 24 * 60 * 60 * 1000)

  return date
}

// TODO: use as facade for every time+date operations
// TODO: change to smth lightweight and moment compatible - day.js, date-fns
export const dateTimeService = moment

// get format of timezone: GTM+8, GTM, GTM-3
export const getGMTTimezone = (timeZoneName) => {
  const momentTimezone = moment().tz(timeZoneName).format('Z') // +05:00, -08:00, 00:00
  const timezoneNumber = parseInt(momentTimezone)

  if (timezoneNumber === 0) {
    return 'GMT'
  } else if (timezoneNumber < 0) {
    return `GMT${timezoneNumber}`
  } else {
    return `GMT+${timezoneNumber}`
  }
}
