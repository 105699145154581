export type ExperimentName = (typeof allExperimentNames)[number]

export type GateName = ''
export type DynamicConfigName =
  | 'user_browser'
  | 'user_country'
  | 'payment_method_orchestrator_config'
  | 'recaptcha_config'
export type LayerName = ''

export const allExperimentNames = [
  'show_combined_pricing_plans_new_summary_payment_ui',
  'show_express_buttons',
  'co_orchestrator_horizontal_en_de',
  'co_orchestrator_horizontal_nl_pl',
  'co_orchestrator_horizontal_not_en_de_nl_pl',
] as const
