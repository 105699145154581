import { isWindowEnv } from 'utils/env.utils'
import { deepObjectPropNamesToCamelCase } from 'utils/nameStyle.utils'
import type { AxiosError, AxiosResponse } from 'axios'

import { parseHeader } from 'utils/header'

import { JSONAPIDocument } from './types'

type AuthorizationCookies = {
  accessToken: string
  refreshToken: string
  expiresAt: string
  refreshExpiresAt: string
}

export const withRefreshTokenInterceptor =
  (axiosInstance, { refreshToken, fingerprint }, refetchToken) =>
  async (error) => {
    const config = error?.config

    if (error?.response?.status === 401 && !config?.sent && !config._retry) {
      config.sent = true
      config._retry = true

      const result: AuthorizationCookies = await refetchToken({ refreshToken, fingerprint })

      if (result?.accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `${result?.accessToken}`,
        }
      }

      return axiosInstance(config)
    }
    return Promise.reject(error)
  }

function isSuccessResponse(responseOrError: AxiosResponse | AxiosError): responseOrError is AxiosResponse {
  return responseOrError.status === 200
}

export const withDebugInterceptor =
  (debugMode, serverSideLogs) =>
  (response: AxiosResponse): any => {
    if (debugMode && !isWindowEnv()) {
      if (isSuccessResponse(response)) {
        const reqHeaders = parseHeader(response.request._header)
        const resHeaders = response.headers
        const respStatus = response.status
        const reqDuration = response.headers['request-duration']

        const logs = JSON.stringify({
          method: response.config.method,
          response: response.data,
          path: response.config.url,
          baseURL: response.config.baseURL,
          reqHeaders,
          resHeaders,
          respStatus,
          reqDuration,
        })

        serverSideLogs.push(logs)
      }
    }

    return response
  }

export const withDebugErrorInterceptor =
  (debugMode, serverSideLogs) =>
  (error: AxiosError): any => {
    if (debugMode && !isWindowEnv()) {
      const reqHeaders = parseHeader(error.response.request._header)
      const resHeaders = error.response.headers
      const respStatus = error.response.status
      const reqDuration = error.response.headers['request-duration']

      const logs = JSON.stringify({
        method: error.response.config.method,
        response: error.response.data,
        path: error.response.config.url,
        reqHeaders,
        resHeaders,
        respStatus,
        reqDuration,
        baseURL: error.response.config.baseURL,
      })

      serverSideLogs.push(logs)
    }

    return error
  }
export function withNormalizeData() {
  return (response) => {
    if (response?.code === 'ERR_NETWORK' || response?.code === 'ERR_CANCELED') {
      return {
        errors: [
          {
            message: response.message,
            code: response.code,
            status: undefined,
          },
        ],
        data: undefined,
        status: undefined,
      }
    }

    if (response?.name === 'AxiosError') {
      return {
        errors: [
          ...(response?.errors || []),
          {
            message: response.message,
            code: response.code,
            status: response.response?.status,
          },
        ],
        data: undefined,
        status: response.response?.status,
      }
    }

    if (response.data?.included) {
      response.included = response.data.included
    }

    if (response.data?.meta?.total_count) {
      response.total_count = response.data.meta.total_count
    }

    if (response.response?.data?.errors) {
      response.errors = response.response?.data?.errors
    }

    response.data = response.data?.data

    return response
  }
}

export const withNormalizeErrorInterceptor = () => (axiosResponse) => {
  if (axiosResponse.error?.response?.status !== 401)
    return Promise.reject(axiosResponse.response.data || axiosResponse.response)
}

export function withCamelCaseKeys(): any {
  return (response) => {
    if (response?.errors?.length) {
      return {
        errors: response.errors,
        status: response?.response?.status,
      }
    }

    return {
      data: Array.isArray(response.data)
        ? response.data.map((item) => deepObjectPropNamesToCamelCase(item))
        : deepObjectPropNamesToCamelCase(response.data),
      status: response.status,
      ...(response.included && Array.isArray(response.included)
        ? { included: response.included.map((item) => deepObjectPropNamesToCamelCase(item)) }
        : {}),
      ...(response.total_count ? { totalCount: response.total_count } : {}),
    }
  }
}

export function withSerializedJSON() {
  return function fromJSONAPI<T>(response) {
    if (response?.errors?.length) {
      return response
    }

    function normalizeResponse(item: JSONAPIDocument<T>) {
      return {
        id: item?.id,
        ...item?.attributes,
        ...(item?.relationships?.covers?.data ? { covers: item?.relationships.covers.data } : {}),
      }
    }

    response.data = Array.isArray(response.data)
      ? response.data.map(normalizeResponse)
      : normalizeResponse(response.data)

    return response
  }
}

export function responseTimeInterceptor(response) {
  const start = response.config.headers['request-startTime']
  const end = process.hrtime(start)
  const milliseconds = Math.round(end[0] * 1000 + end[1] / 1000000)
  response.headers['request-duration'] = milliseconds

  return response
}
