import { allExperimentNames, DynamicConfigName, ExperimentName, GateName, LayerName } from 'shared/types/experiments'
import { StatsigClient } from '@statsig/js-client'

type FeatureGate = ReturnType<StatsigClient['getFeatureGate']>
type Experiment = ReturnType<StatsigClient['getExperiment']>
type Layer = ReturnType<StatsigClient['getLayer']>
type DynamicConfig = ReturnType<StatsigClient['getDynamicConfig']>

const emptyFeatureGate: FeatureGate = {
  name: '',
  value: false,
  ruleID: '',
  details: null,
  __evaluation: null,
}

const emptyExperiment: Experiment = {
  name: '',
  ruleID: '',
  value: {},
  details: null,
  groupName: null,
  __evaluation: null,
  get: (key, fallback) => fallback as any,
}

const emptyLayer: Layer = {
  name: '',
  ruleID: '',
  details: null,
  groupName: null,
  __value: {},
  __evaluation: null,
  get: (key, fallback) => fallback as any,
}

const emptyDynamicConfig: DynamicConfig = {
  name: '',
  ruleID: '',
  value: {},
  details: null,
  __evaluation: null,
  get: (key, fallback) => fallback as any,
}

export class ExperimentsStore {
  storeName = 'ExperimentsStore'
  private client: StatsigClient
  private isActive = false

  setClient(client: StatsigClient) {
    this.client = client
    this.isActive = true
  }

  getFeatureGate(name: GateName): FeatureGate {
    if (!this.isActive) {
      return emptyFeatureGate
    }
    return this.client.getFeatureGate(name)
  }

  getLayer(name: LayerName): Layer {
    if (!this.isActive) {
      return emptyLayer
    }
    return this.client.getLayer(name)
  }

  getDynamicConfig(name: DynamicConfigName): DynamicConfig {
    if (!this.isActive) {
      return emptyDynamicConfig
    }
    return this.client.getDynamicConfig(name)
  }

  logEvent(eventName: string, value: string, metadata?: Record<string, string>): void {
    if (!this.isActive) {
      return
    }
    this.client.logEvent(eventName, value, metadata)
  }

  getExperiment = (name: ExperimentName, options?: { disableExposureLog: boolean }): Experiment => {
    if (!this.isActive) {
      return emptyExperiment
    }
    return this.client.getExperiment(name, options)
  }

  get allActiveExperiments(): Record<string, { name: string; value: Record<string, unknown> }> {
    if (!this.isActive) {
      return {}
    }

    return allExperimentNames.reduce((acc, name) => {
      const experiment = this.getExperiment(name, { disableExposureLog: true })

      // true, when user is in experiment, it is group name of the experiment
      if (experiment.groupName) {
        acc[name] = {
          name: experiment.name,
          // DWH uses group name as join as reference
          value: { ...experiment.value, group: experiment.groupName },
        }
      }

      return acc
    }, {})
  }
}
